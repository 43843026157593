<template>
    <div id="login-page">
        <div class="form-wrapper">
            <img src="~/assets/img/ramen.png" class="ramen" width="200" height="200" alt="ramen">
            <img src="~/assets/img/donut.png" class="donut" width="200" height="200" alt="donut">

            <form @submit.prevent="submit">
                <h3>
                    <span @click="currentForm = 'login'" :class="{'active text-green-400': currentForm === 'login'}">
                        Login
                    </span>
                    <UDivider
                            class="divider"
                            label="OR"
                            orientation="vertical"
                            :ui="{ label: 'text-slate-300 text-xs' }"
                    />
                    <span @click="currentForm = 'signup'" :class="{'active text-green-400': currentForm === 'signup'}">
                        Signup
                    </span>
                </h3>

                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="login">
                    Email
                </label>
                <input
                        required
                        id="login"
                        type="text"
                        name="new-login"
                        v-model="email"
                        placeholder="example@gmail.com"
                        class="mb-3 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                >

                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="password">
                    Password
                </label>
                <input
                        required
                        id="password"
                        type="password"
                        name="new-password"
                        v-model="password"
                        placeholder="******"
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                >

                <template v-if="currentForm === 'signup'">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="password">
                        Repeat password
                    </label>
                    <input
                            required
                            id="password"
                            type="password"
                            name="repeat-new-password"
                            v-model="repeatPassword"
                            placeholder="******"
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                    >
                </template>

                <UButton class="submit-button" type="submit" :loading="loading">
                    {{ currentForm === 'login' ? 'Login' : 'Signup' }}
                </UButton>
            </form>
        </div>
    </div>
</template>

<script setup>
definePageMeta({
    layout: 'login',
});

useHead({
    title: 'MyMenu | Authorization',
    meta: [
        { name: 'description', content: 'My authorization' }
    ],
    bodyAttrs: {
        class: 'login-body'
    },
})

const router = useRouter();
const user = useUserStore();

const email = ref('');
const password = ref('');
const repeatPassword = ref('');

const loading = ref(false);
const currentForm = ref('login');

const checkAuth = () => {
    if (user.isAuthenticated) {
        router.push('/manager');
    }
}

const submit = async () => {
    if (currentForm.value === 'signup' && password.value !== repeatPassword.value) {
        const {notify} = useNotification();

        notify({
            type: 'error',
            title: 'Error',
            position: 'top center',
            text: 'The passwords must match!',
        });
        return;
    }

    const data = {
        email: email.value,
        password: password.value
    };

    loading.value = true;
    const res = await (currentForm.value === 'signup' ? user.register(data) : user.login(data));
    loading.value = false;

    if (res) {
        checkAuth();
    }
}

checkAuth();
</script>

<style scoped lang="scss">
@import '~/assets/css/rfs.scss';

#login-page {
    display: flex;
    overflow: hidden;
    min-height: 100vh;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #2d2d2d;
}

.ramen {
    top: -90px;
    left: -70px;
    position: absolute;
    transform: rotate(-20deg);
}

.donut {
    right: -70px;
    bottom: -90px;
    position: absolute;
    transform: rotate(35deg);
}

.form-wrapper {
    width: 400px;
    display: flex;
    max-width: 90%;
    position: relative;
    align-items: center;
    justify-content: center;
}

form {
    width: 100%;
    padding: 50px 35px;
    position: relative;
    border-radius: 10px;
    backdrop-filter: blur(3px);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    border: 2px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.13);

    h3 {
        width: 100%;
        height: 52px;
        font-weight: 500;
        text-align: center;
        display: inline-flex;
        @include font-size(32px);
        @include line-height(42px);

        .divider {
            flex: 0.25;
        }

        span {
            flex: 1;
            cursor: pointer;
            align-items: center;
            display: inline-flex;
            justify-content: center;

            &.active {
                text-decoration: underline;
                text-underline-offset: 3.5px;
                text-decoration-thickness: 1.5px;
            }
        }
    }
}

form * {
    border: none;
    outline: none;
    letter-spacing: 0.5px;
    font-family: "Poppins", sans-serif;
}

label {
    display: block;
    color: #ffffff;
    font-size: 16px;
    margin-top: 30px;
    font-weight: 500;
}

input {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
}

::placeholder {
    color: #e5e5e5;
}

button, .submit-button {
    width: 100%;
    display: flex;
    color: #080710;
    cursor: pointer;
    padding: 15px 0;
    font-size: 18px;
    margin-top: 50px;
    font-weight: 600;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: #ffffff !important;
}
</style>
